// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-art-modal-js": () => import("./../../../src/pages/artModal.js" /* webpackChunkName: "component---src-pages-art-modal-js" */),
  "component---src-pages-code-js": () => import("./../../../src/pages/code.js" /* webpackChunkName: "component---src-pages-code-js" */),
  "component---src-pages-image-js": () => import("./../../../src/pages/image.js" /* webpackChunkName: "component---src-pages-image-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-single-js": () => import("./../../../src/pages/single.js" /* webpackChunkName: "component---src-pages-single-js" */)
}

